import React from "react";
import backgroundImage from "../assets/imagenew/bgimage1sar.jpg";
// import backgroundImage1 from "../assets/imagenew/bgimage2sar.jpeg";
import img from "../assets/imagenew/smallcell.jpg";
// import img1 from "../assets/imagenew/sarsamllcell.png";
import img2 from "../assets/imagenew/smallcellsar2.jpeg";
import img5 from "../assets/imagenew/towerinfraimage.jpeg";
import img3 from "../assets/imagenew/saarsmallcell3.jpg";
import img4 from "../assets/imagenew/saarsmallcell4.jpg";
import img6 from "../assets/imagenew/file.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function SmallCell() {
  return (
    <>
      <Helmet>
        <title>
          SAR Televenture | Your Trusted Partner for Tower Infrastructure
          Solutions
        </title>
        <link rel="canonical" href="https://sarteleventure.com/tower-infra" />
        <meta
          name="keywords"
          content="Home Automation Solutions, Home Automation Company, Energy Management, Prepaid Meter, Smart CCTV Solutions, Intercom Service Provider, Smart CCTV Solutions In Delhi NCR, Automatic Boom Barrier Solutions, Video Door Phone System, Home Automation Company In Delhi NCR, FTTH, FTTX, GPON, Fire Alarm Company In Delhi NCR, Fire Alarm System, Intercom Service Provider In Delhi NCR"
        />
        <meta
          name="description"
          content=" SAR Televenture delivers robust tower infrastructure solutions, ensuring reliable connectivity to support growth and innovation in telecommunications."
        />
        <meta
          name="title"
          content=" SAR Televenture | Your Trusted Partner for Tower Infrastructure Solutions "
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sarteleventure.com/" />
        <meta
          property="og:image"
          content="https://sarteleventure.com/path_to_image.jpg"
        />
        <meta
          property="og:description"
          content="SAR Televenture offers advanced FTTH solutions, providing high-speed and reliable fiber optic technology for modern telecommunications."
        />
        <meta property="og:site_name" content="SAR Televenture" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:updated_time" content="2024-04-19T00:00:00Z" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="https://sarteleventure.com/" />
        <meta
          name="twitter:description"
          content="SAR Televenture is the top fire alarm system and fire detection system company - designing and offering a variety of fire alarm systems.."
        />
        <meta
          name="twitter:image"
          content="https://sarteleventure.com/assets/imagenew/para-logo.png"
        />
      </Helmet>

      <div
        className="inner-banner"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="inner-title  ">
            <h3 className="text-black">TOWER INFRASTRUCTURE</h3>
            {/* <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="bx bx-chevrons-right"></i>
              </li>
              <li>
                <Link to="#">Small Cell Networkingy</Link>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <div
        className=" flex justify-center flex-col 
       mx-auto items-center lg:p-16  ml-16  w-[80%]  "
      >
        <h3
          className=" py-3  flex  justify-center items-center w-full h-auto "
          style={{ fontSize: "25px" }}
        >
          Types of Tower Infra
        </h3>
        <p className=" flex justify-center items-center  w-[40%]   text-center">
          Tower Infra comes in various types, each tailored to specific
          deployment scenarios and requirements. Generally, these types are
          smaller than macro cells
        </p>
        <span></span>
      </div>
      <div class="row solutions-white   " style={{ marginTop: "14px" }}>
        <div class="col-0 col-lg-2 container"></div>
        <div class="col-12 col-lg-8 container">
          <div class="row container ">
            <div class="col-12 col-lg-6">
              <img
                src={img5}
                alt="Ground Based Towers"
                title="Ground Based Towers"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div
              class="col-12 col-lg-6 body-copy container"
              style={{ marginTop: "14px" }}
            >
              <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl container">
                Ground Based Towers
              </h1>
              <p style={{ textAlign: "justify" }} className="container">
                As part of our ongoing efforts to optimize deployment site
                costs, we have developed several innovative, low-cost
                multi-tenant solutions. These solutions require minimal ground
                footprint, are easy to deploy, and are especially useful in
                areas where land acquisition is challenging, such as urban and
                semi-urban regions. Our low-cost solutions have been
                successfully implemented in large numbers throughout the
                country.
              </p>
            </div>
          </div>
        </div>
        <div class="col-0 col-lg-2"></div>
      </div>
      <div class="row  " style={{ marginTop: "14px" }}>
        <div class="col-0 col-lg-2 container "></div>
        <div class="col-12 col-lg-8 container">
          <div class="row container">
            <div
              class="col-12 col-lg-6 order-2 order-lg-1  body-copy "
              style={{ marginTop: "40px" }}
            >
              <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl container">
                Monopoles
              </h1>
              <p style={{ textAlign: "justify" }} className="container">
                Deploying ground-based tower sites in urban areas is challenging
                due to the limited availability of large land spaces. Monopoles,
                with their compact ground footprint, are an ideal solution for
                such environments. SAR TELEVENTURE India offers a range of
                aesthetically pleasing and versatile monopole designs tailored
                for city settings. Our monopoles have been successfully
                installed in public spaces like children’s parks, rotary
                islands, and other locations, seamlessly integrating with the
                surroundings and ensuring our customers can provide consistent
                coverage in urban areas.
              </p>
            </div>
            <div class="col-12 order-1 order-lg-2 col-lg-6 ">
              <img
                src={img6}
                alt="Low Cost Towers"
                title="Low Cost Towers"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <div class="col-0 col-lg-2 container"></div>
      </div>
      <div class="row solutions-white   " style={{ marginTop: "14px" }}>
        <div class="col-0 col-lg-2 "></div>
        <div class="col-12 col-lg-8 container">
          <div class="row container">
            <div class="col-12 col-lg-6">
              <img
                src={img2}
                alt="Ground Based Towers"
                title="Ground Based Towers"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div
              class="col-12 col-lg-6 body-copy container"
              style={{ marginTop: "14px" }}
            >
              <h1 className="max-w-2xl mb-4 text-2xl font-bold tracking-tight leading-none md:text-2xl xl:text-2xl container">
                Cell on Wheels
              </h1>
              <p style={{ textAlign: "justify" }} className="container">
                With a fleet of over 300 Cell on Wheels (CoWs), we offer rapid
                deployment solutions to extend wireless network coverage at
                special events such as exhibitions, large conventions, and
                sporting events, as well as in disaster-stricken areas where
                conventional coverage is either overloaded or disrupted. CoWs
                are also effectively used to establish sites in sensitive
                locations where permanent site construction is prohibited.
              </p>
            </div>
          </div>
        </div>
        <div class="col-0 col-lg-2"></div>
      </div>
      <div
        className={`security-area pt-100 pb-70 security-area pt-100 pb-70 bg-cover bg-cente  opacity-80`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="absolute inset-0  opacity-80"></div>
        <div className="container   z-10">
          <div className="section-title text-center z-10 flex  center flex-col items-center lg:ml-[250px]">
            <h2 className="leading-3 text-white " style={{ fontSize: "25px" }}>
              Applications of Tower Infrastructure
            </h2>
          </div>
          <div className="row pt-45">
            <div className="col-lg-4 col-sm-6">
              <div className="security-card" style={{ textAlign: "center" }}>
                <i className="flaticon-cyber-security text-center"></i>
                <h3>
                  <Link
                    to="#"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    Private Networks
                  </Link>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Tower infrastructure is being deployed in private networks for
                  enterprise campuses, industrial facilities, and smart cities
                  to provide dedicated coverage, capacity, and secure, reliable
                  communication tailored to the specific needs of each
                  organization.
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="security-card" style={{ textAlign: "center" }}>
                <i className="flaticon-cyber-security text-center"></i>

                <h3>
                  <Link
                    to="#"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    Dense Urban Areas
                  </Link>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  In densely populated urban areas, small cells address high
                  wireless demand. Mounted on street lights, utility poles, or
                  buildings, they deliver localized coverage, especially in
                  locations where tall structures block macro cell signals..{" "}
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="security-card" style={{ textAlign: "center" }}>
                <i className="flaticon-cyber-security text-center"></i>
                <h3>
                  <Link
                    to="#"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    Indoor Environments
                  </Link>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Tower infrastructure is used in indoor spaces like malls,
                  airports, stadiums, and office buildings. These crowded areas
                  struggle with reliable coverage, and tower infrastructure
                  enhances signal strength, capacity, and data speeds, improving
                  the indoor user experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="security-card" style={{ textAlign: "center" }}>
                <i className="flaticon-cyber-security text-center"></i>
                <h3>
                  <Link
                    to="#"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    Rural and Remote Areas
                  </Link>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Small cells extend wireless coverage in rural or remote areas
                  where traditional tower infrastructure service is limited.
                  They bridge the digital divide, providing connectivity to
                  underserved communities and enhancing access to mobile
                  services.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="security-card" style={{ textAlign: "center" }}>
                <i className="flaticon-cyber-security text-center"></i>

                <h3>
                  <Link
                    to="#"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    Improved Coverage and Capacity
                  </Link>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  Tower infrastructure boosts network capacity in dense areas or
                  where macro cells have limited coverage. It improves indoor
                  service, fills gaps, extends signal range, and offloads
                  traffic, enhancing speeds. <br /> <br />
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="security-card" style={{ textAlign: "center" }}>
                <i className="flaticon-cyber-security text-center"></i>
                <h3>
                  <Link
                    to="#"
                    style={{ textAlign: "center", fontSize: "30px" }}
                  >
                    5G Network Deployment
                  </Link>
                </h3>
                <p style={{ textAlign: "justify" }}>
                  With the advent of 5G technology, tower infrastructure is
                  essential for supporting higher frequency bands and denser
                  network architectures. It enhances data rates and reduces
                  latency by bringing the network closer to users. <br /> <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="choose-area pt-100 pb-70   "
        style={{ backgroundColor: "gray" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="max-w-2xl mb-4 text-2xl  tracking-tight leading-none md:text-2xl xl:text-2xl text-white">
                  How Tower Infrastructure Works: A Step-by-Step Explanation
                </h1>
                <div className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-0 container ">
                  <ul className="list-disc text-white">
                    <li>
                      <span className=" font-bold">
                        Deployment and Installation:
                      </span>
                      Tower infrastructure is deployed in busy areas or places
                      where coverage is sparse. These towers are often mounted
                      on street lights, utility poles, rooftops, or other
                      existing structures to blend seamlessly with the
                      environment.
                    </li>
                    <li>
                      <span className=" font-bold">
                        Radio Frequency Communication:
                      </span>
                      The tower infrastructure uses radio frequency (RF) signals
                      to connect with devices like smartphones and IoT gadgets.
                      It operates on frequency bands allocated for cellular
                      networks, including 3G, 4G LTE, and 5G.
                    </li>
                    <li>
                      <span className=" font-bold">Localized Coverage:</span>
                      Tower infrastructure provides focused coverage, typically
                      covering a smaller area than macro cell towers. The range
                      is affected by factors such as transmit power, antenna
                      configuration, frequency bands, and environmental
                      conditions.
                    </li>
                    <li>
                      <span className=" font-bold">Network Coordination: </span>
                      The tower infrastructure is part of a network managed by a
                      central controller. This system oversees resource
                      allocation and ensures smooth transitions between small
                      cells and macro towers, maintaining continuous service for
                      users.
                    </li>
                    <li>
                      <span className=" font-bold">Backhaul Connection : </span>
                      To connect to the core network, tower infrastructure uses
                      a backhaul link, which can be either wired or wireless .
                      This link carries data traffic to and from the network.
                    </li>
                    <li>
                      <span className=" font-bold">
                        Integration with Macro Cells:
                      </span>
                      Tower infrastructure and macro cell towers work in concert
                      to ensure smooth transitions as users move between small
                      cells and macro cells. This integration helps maintain a
                      reliable and consistent connection.
                    </li>
                    <li>
                      <span className=" font-bold">
                        Network Management and Optimization:
                      </span>
                      Providers actively manage and optimize the infrastructure
                      to enhance network performance. They monitor the system,
                      gather data, and adjust settings to improve coverage,
                      capacity, and overall service quality.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="choose-img">
                <img src={img3} alt="Images" className="" />
              </div>
              <div className=" hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img src={img4} alt="Images" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SmallCell;

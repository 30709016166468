import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import ContactUS from "./Components/ContactUS";
import { useEffect, useState } from "react";
import SmartHome from "./Components/SmartHome";
import FTTH from "./Components/FTTH";

import Threepageinone from "./Components/Threepageinone";
import Footercallus from "./Components/Footercallus";
import Videodoor from "./Components/Videodoor";
import Smartprepaidmeter from "./Components/Smartprepaidmeter";
import InterCom from "./Components/InterCom";

import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/fonts/flaticon.css";
import "./assets/css/boxicons.min.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/nice-select.min.css";
import "./assets/css/meanmenu.css";
import "../src/assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/theme-dark.css";

import Eroor from "./Components/Eroor";
import Thankyou from "./Components/Thankyou";
import Investor from "./Components/Investor";
import SmallCell from "./Components/SmallCell";
import Media from "./Components/Media";
import Sarmeter from "./Components/Pages/Sarmeter";
import Sarfiber from "./Components/Pages/Sarfiber";
import Sarautomation from "./Components/Pages/Sarautomation";
import SmartHomeSystems from "./Components/Pages/SmartHomeSystems";
import SmartHomeAutomationSystem from "./Components/Pages/SmartHomeAutomationSystem";
import SmartHomeAutomation from "./Components/Pages/SmartHomeAutomation";
import SmartCCTVSolutions from "./Components/Pages/SmartCCTVSolutions";
import PrepaidEnergyMeter from "./Components/Pages/PrepaidEnergyMeter";
import SecurityCameraCompany from "./Components/Pages/SecurityCameraCompany";
import SmartAutomation from "./Components/Pages/SmartAutomation";
import SecuritySolution from "./Components/Pages/SecuritySolution";
import HomeDomotics from "./Components/Pages/HomeDomotics";
import HomeAutomationSystem from "./Components/Pages/HomeAutomationSystem";
import IntercomServiceProvider from "./Components/Pages/IntercomServiceProvider";
import HomeAutomation from "./Components/Pages/HomeAutomation";
import HomeAutomationSolutions from "./Components/Pages/HomeAutomationSolutions";
import HomeAutomationIoTSolutions from "./Components/Pages/HomeAutomationIoTSolutions";
import HomeAutomationCompany from "./Components/Pages/HomeAutomationCompany";
import HomeAutomationandSecuritySystem from "./Components/Pages/HomeAutomationandSecuritySystem";
import FireMonitoringSystem from "./Components/Pages/FireMonitoringSystem";
import FireDetectionandFireAlarmSystem from "./Components/Pages/FireDetectionandFireAlarmSystem";
import BestFireAlarmSystem from "./Components/Pages/BestFireAlarmSystem";
import BestEnergyManagementSolutions from "./Components/Pages/BestEnergyManagementSolutions";
import BestCCTVSecurityCameras from "./Components/Pages/BestCCTVSecurityCameras";
import BestFireAlarmSolution from "./Components/Pages/BestFireAlarmSolution";
import WirelessFireDetectionSystem from "./Components/Pages/WirelessFireDetectionSystem";
import WirelessIntercominNoida from "./Components/Pages/WirelessIntercominNoida";
import WirlessIntercom from "./Components/Pages/WirlessIntercom";
import SmartHomeSolutions from "./Components/Pages/SmartHomeSolutions";
import VideoDoorPhoneSystem from "./Components/Pages/VideoDoorPhoneSystem";
import SmartPrepaidMeterinNoida from "./Components/Pages/SmartPrepaidMeterinNoida";
import SmartPrepaidMeter from "./Components/Pages/SmartPrepaidMeter";
import BestEnergyManagementSolutionsinNoida from "./Components/Pages/BestEnergyManagementSolutionsinNoida";
import BestFireAlarmSolutioninNoida from "./Components/Pages/BestFireAlarmSolutioninNoida";
import BestFireAlarmSystemsinNoida from "./Components/Pages/BestFireAlarmSystemsinNoida";
import FireMonitoringSysteminNoida from "./Components/Pages/FireMonitoringSysteminNoida";
import FireDetectionAndFireAlarmSysteminNoida from "./Components/Pages/FireDetectionAndFireAlarmSysteminNoida";
import HomeAutomationAndSecuritySysteminNoida from "./Components/Pages/HomeAutomationAndSecuritySysteminNoida";
import HomeAutomationCompanyinNoida from "./Components/Pages/HomeAutomationCompanyinNoida";
import HomeAutomationinNoida from "./Components/Pages/HomeAutomationinNoida";
import HomeAutomationSolutionsinNoida from "./Components/Pages/HomeAutomationSolutionsinNoida";
import HomeAutomationIoTinNoida from "./Components/Pages/HomeAutomationIoTinNoida";
import HomeAutomationSysteminNoida from "./Components/Pages/HomeAutomationSysteminNoida";
import HomeDomoticsinNoida from "./Components/Pages/HomeDomoticsinNoida";
import BestCCTVSecurityCamerasinNoida from "./Components/Pages/BestCCTVSecurityCamerasinNoida";
import PrepaidEnergyMeterinNoida from "./Components/Pages/PrepaidEnergyMeterinNoida";
import InterServicesProviderInNoida from "./Components/Pages/InterServicesProviderInNoida";
import SecurityCameraCompanyinNoida from "./Components/Pages/SecurityCameraCompanyinNoida";
import SmartAutomationinNoida from "./Components/Pages/SmartAutomationinNoida";
import SecuritySolutioninNoida from "./Components/Pages/SecuritySolutioninNoida";
import SmartCCTVsolutionsinNoida from "./Components/Pages/SmartCCTVsolutionsinNoida";
import SmartHomeAutomationinNoida from "./Components/Pages/SmartHomeAutomationinNoida";
import SmartHomeAutomationSysteminNoida from "./Components/Pages/SmartHomeAutomationSysteminNoida";
import SmartHomeSystemsinNoida from "./Components/Pages/SmartHomeSystemsinNoida";
import SmartHomeSolutionsinNoida from "./Components/Pages/SmartHomeSolutionsinNoida";
import WirelessFireDetectionSysteminNoida from "./Components/Pages/WirelessFireDetectionSysteminNoida";
import VideoDoorPhoneSysteminNoida from "./Components/Pages/VideoDoorPhoneSysteminNoida";
import Demo from "./Components/Pages/demo";
import Noida from "./Components/Pages/Noida";
import Chandigarh from "./Components/Pages/Chandigarh";
import Ghaziabad from "./Components/Pages/Ghaziabad";
import Jaipur from "./Components/Pages/Jaipur";
import Hyderabad from "./Components/Pages/Hyderabad";
import Mohali from "./Components/Pages/Mohali";
import Pune from "./Components/Pages/Pune";
import Lucknow from "./Components/Pages/Lucknow";
import Kanpur from "./Components/Pages/Kanpur";

// import Media from "./Components/Media";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="preloader">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Navbar />
                  <div className="main-content">
                    <Outlet />
                  </div>
                  <Footercallus />
                  <Footer />
                </>
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/contact-us" element={<ContactUS />} />
              <Route path="/smarthomeautomation" element={<SmartHome />} />
              <Route path="/ftth-infra-solution" element={<FTTH />} />
              <Route path="/security/:page" element={<Threepageinone />} />
              <Route path="/cctv" element={<Threepageinone />} />
              <Route path="/tower-infra" element={<SmallCell />} />
              <Route path="/intercom" element={<InterCom />} />
              <Route path="/investor" element={<Investor />} />
              <Route path="/Media" element={<Media />} />
              <Route path="/sarmeter" element={<Sarmeter />} />
              <Route path="/Sarfiber" element={<Sarfiber />} />
              <Route path="/Sarautomation" element={<Sarautomation />} />
              <Route path="/Wireless-intercom" element={<WirlessIntercom />} />
              <Route
                path="/Video-Door-Phone-System"
                element={<VideoDoorPhoneSystem />}
              />
              <Route
                path="/Wireless-Fire-Detection-System"
                element={<WirelessFireDetectionSystem />}
              />
              <Route
                path="/Smart-Prepaid-Meter"
                element={<SmartPrepaidMeter />}
              />
              <Route
                path="/Wireless-Intercom-in-Noida"
                element={<WirelessIntercominNoida />}
              />
              <Route
                path="/Smart-Prepaid-Meter-in-Noida"
                element={<SmartPrepaidMeterinNoida />}
              />
              <Route
                path="/Smart-Home-Solutions"
                element={<SmartHomeSolutions />}
              />
              <Route
                path="/Smart-Home-Systems"
                element={<SmartHomeSystems />}
              />
              <Route
                path="/Smart-Home-Automation-System"
                element={<SmartHomeAutomationSystem />}
              />
              <Route
                path="/Smart-Home-Automation"
                element={<SmartHomeAutomation />}
              />
              <Route
                path="/Smart-CCTV-Solutions"
                element={<SmartCCTVSolutions />}
              />
              <Route
                path="/Prepaid-Energy-Meter"
                element={<PrepaidEnergyMeter />}
              />
              <Route
                path="/Security-Camera-Company"
                element={<SecurityCameraCompany />}
              />
              <Route path="/Smart-Automation" element={<SmartAutomation />} />
              <Route path="/Security-Solution" element={<SecuritySolution />} />
              <Route path="/Home-Domotics" element={<HomeDomotics />} />
              <Route
                path="/Home-Automation-System"
                element={<HomeAutomationSystem />}
              />
              <Route
                path="/Intercom-Service-Provider"
                element={<IntercomServiceProvider />}
              />
              <Route path="/Home-Automation" element={<HomeAutomation />} />
              <Route
                path="/Home-Automation-Solutions"
                element={<HomeAutomationSolutions />}
              />
              <Route
                path="/Home-Automation-IoT-Solutions"
                element={<HomeAutomationIoTSolutions />}
              />
              <Route
                path="/Home-Automation-Company"
                element={<HomeAutomationCompany />}
              />
              <Route
                path="/Home-Automation-and-Security-System"
                element={<HomeAutomationandSecuritySystem />}
              />
              <Route
                path="/Fire-Monitoring-System"
                element={<FireMonitoringSystem />}
              />
              <Route
                path="/Fire-Detection-and-Fire-Alarm-System"
                element={<FireDetectionandFireAlarmSystem />}
              />
              <Route
                path="/Best-Fire-Alarm-Solution"
                element={<BestFireAlarmSolution />}
              />
              <Route
                path="/Best-Fire-Alarm-System"
                element={<BestFireAlarmSystem />}
              />
              <Route
                path="/Best-Energy-Management-Solutions"
                element={<BestEnergyManagementSolutions />}
              />
              <Route
                path="/Best-CCTV-Security-Cameras"
                element={<BestCCTVSecurityCameras />}
              />
              <Route
                path="/Best-Energy-Management-Solutions-in-Noida"
                element={<BestEnergyManagementSolutionsinNoida />}
              />
              <Route
                path="/Best-Fire-Alarm-Solution-in-Noida"
                element={<BestFireAlarmSolutioninNoida />}
              />
              <Route
                path="/Best-Fire-Alarm-Systems-in-Noida"
                element={<BestFireAlarmSystemsinNoida />}
              />
              <Route
                path="/Fire-Monitoring-System-in-Noida"
                element={<FireMonitoringSysteminNoida />}
              />
              <Route
                path="/Fire-Detection-And-Fire-Alarm-System-in-Noida"
                element={<FireDetectionAndFireAlarmSysteminNoida />}
              />
              <Route
                path="/Home-Automation-And-Security-System-in-Noida"
                element={<HomeAutomationAndSecuritySysteminNoida />}
              />
              <Route
                path="/Home-Automation-Company-in-Noida"
                element={<HomeAutomationCompanyinNoida />}
              />
              <Route
                path="/Home-Automation-in-Noida"
                element={<HomeAutomationinNoida />}
              />
              <Route
                path="/Home-Automation-Solutions-in-Noida"
                element={<HomeAutomationSolutionsinNoida />}
              />
              <Route
                path="/Home-Automation-IoT-in-Noida"
                element={<HomeAutomationIoTinNoida />}
              />
              <Route
                path="/Home-Automation-System-in-Noida"
                element={<HomeAutomationSysteminNoida />}
              />
              <Route
                path="/Home-Domotics-in-Noida"
                element={<HomeDomoticsinNoida />}
              />
              <Route
                path="/Best-CCTV-Security-Cameras-in-Noida"
                element={<BestCCTVSecurityCamerasinNoida />}
              />
              <Route
                path="/Best-CCTV-Security-Cameras-in-Noida"
                element={<BestCCTVSecurityCamerasinNoida />}
              />
              <Route
                path="/Prepaid-Energy-Meter-in-Noida"
                element={<PrepaidEnergyMeterinNoida />}
              />
              <Route
                path="/Intercom-Services-Provider-in-Noida"
                element={<InterServicesProviderInNoida />}
              />
              <Route
                path="/Security-Camera-Company-in-Noida"
                element={<SecurityCameraCompanyinNoida />}
              />
              <Route
                path="/Smart-Automation-in-Noida"
                element={<SmartAutomationinNoida />}
              />
              <Route
                path="/Security-Solution-in-Noida"
                element={<SecuritySolutioninNoida />}
              />
              <Route
                path="/Smart-CCTV-solutions-in-Noida"
                element={<SmartCCTVsolutionsinNoida />}
              />
              <Route
                path="/Smart-Home-Automation-in-Noida"
                element={<SmartHomeAutomationinNoida />}
              />
              <Route
                path="/Smart-Home-Automation-System-in-Noida"
                element={<SmartHomeAutomationSysteminNoida />}
              />
              <Route
                path="/Smart-Home-Systems-in-Noida"
                element={<SmartHomeSystemsinNoida />}
              />
              <Route
                path="/Smart-Home-Solutions-in-Noida"
                element={<SmartHomeSolutionsinNoida />}
              />
              <Route
                path="/Wireless-Fire-Detection-System-in-Noida"
                element={<WirelessFireDetectionSysteminNoida />}
              />
              <Route
                path="/Video-Door-Phone-System-in-Noida"
                element={<VideoDoorPhoneSysteminNoida />}
              />
              <Route path="/Demo" element={<Demo />} />
              <Route path="/Noida" element={<Noida />} />
              <Route path="/Chandigarh" element={<Chandigarh />} />
              <Route path="/Ghaziabad" element={<Ghaziabad />} />
              <Route path="/Jaipur" element={<Jaipur />} />
              <Route path="/Hyderabad" element={<Hyderabad />} />
              <Route path="/Mohali" element={<Mohali />} />
              <Route path="/Pune" element={<Pune />} />
              <Route path="/Lucknow" element={<Lucknow />} />
              <Route path="/Kanpur" element={<Kanpur />} />
              <Route
                path="/smartprepaidmeter"
                element={<Smartprepaidmeter />}
              />
              <Route path="/video-door-phone" element={<Videodoor />} />
            </Route>
            <Route path="/*" element={<Eroor />} status={404} />
            <Route path="/thankyou" element={<Thankyou />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
